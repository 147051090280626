import { Layout } from "./Layout";
import React, { useEffect } from "react";

export function Impressum() {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Impressum - Futedu";

    return () => {
      document.title = originalTitle; // Setzt den Titel zurück, wenn die Komponente unmountet wird
    };
  }, []);

  return (
    <Layout pageTitle={"Impressum"}>
      <div className="p-8 max-w-xl mx-auto text-primary">
        <h1 className="text-5xl font-bold mb-4">Impressum</h1>
        <h1 className="text-2xl font-bold mb-4">Futedu GbR</h1>
        <address className="not-italic mb-4">
          <p>Stieglitzstr. 8</p>
          <p>04229 Leipzig</p>
          <p>Deutschland</p>
        </address>

        <p className="mb-4">
          <span className="font-semibold">E-Mail:</span>{" "}
          <a href="mailto:info@futedu.de" className="text-blue-500 underline">
            info@futedu.de
          </a>
        </p>
        <p className="mb-4">
          <span className="font-semibold">
            Vertretungsberechtigte Gesellschafter:
          </span>{" "}
          Paul Sieber, Valentin Stenz, Victor Stenz, Laoise Hynes
        </p>
        <p className="mb-4">
          USt. wird nicht ausgewiesen (Kleinunternehmerregelung)
        </p>
        <p className="mb-4">
          <span className="font-semibold">
            Verantwortliche/r i.S.d. § 18 Abs. 2 MStV:
          </span>{" "}
          Paul Sieber, Davidstraße 1, 04109 Leipzig
        </p>
        <p className="mb-4">
          <span className="font-semibold">
            Plattform der EU-Kommission zur Online-Streitbeilegung:
          </span>{" "}
          <a
            href="https://ec.europa.eu/odr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            https://ec.europa.eu/odr
          </a>
        </p>
        <p>
          Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
        </p>
      </div>
    </Layout>
  );
}
