// PersSearch.js
import React, { useState, useEffect } from "react";
import { SearchCourses } from "./SearchCourses";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { Layout } from "../Layout.js";
import { useNavigate } from "react-router-dom";
import { DefaultCourses } from "./DefaultCourses";
import IconButton from "@mui/material/IconButton";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import FilterComponent from "./Recommendations/FilterComponent.js";
import Suche from "./Suche/Suche.js";
import { courseList } from "./Suche/CourseList.js";
import AutoPopup from "../../components/PopUp.js";
import Alert from "@mui/material/Alert";
import { SkeletonLoader } from "./Suche/SkeletonLoader";

export function PersSearch() {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [queryVector, setQueryVector] = useState(null);
  const [showDefaultCourses, setShowDefaultCourses] = useState(true);

  const [selectedKategorien, setSelectedKategorien] = useState([]);
  const [selectedTyp, setSelectedTyp] = useState([]);
  const [selectedSchwierigkeit, setSelectedSchwierigkeit] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(null);

  const [queryPineconeCalled, setQueryPineconeCalled] = useState(false); // New state variable

  const navigate = useNavigate();

  const courseOptions = courseList.map((course) => ({
    value: course,
    label: course,
  }));
  const [showFilter, setShowFilter] = useState(true);

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    const fetchQueryVector = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            delete userData.email;
            delete userData.stripeId;
            delete userData.stripeLink;

            const fieldNames = [
              "Verträglichkeit",
              "Gewissenhaftigkeit",
              "Extrovertiert",
              "Neurotizismus",
              "Offenheit",
              "Kreativität",
              "Empathie",
              "Zahlenreihen",
              "Sprache",
              "Koordinieren",
              "Helfen",
              "Kreieren",
              "Analysieren",
              "Verhandeln",
              "Naturwissenschaften",
              "Technik",
              "Gesellschaft",
              "Künste",
            ];

            let values = [];
            fieldNames.forEach((fieldName) => {
              if (userData.hasOwnProperty(fieldName)) {
                values.push(userData[fieldName]);
              } else {
                values.push(null);
              }
            });

            const hasNull = values.some(
              (value) => value === null || value === undefined
            );

            if (hasNull) {
              const nonNullCount = values.filter(
                (value) => value !== null && value !== undefined
              ).length;

              if (nonNullCount >= 3) {
                setError("Du hast nur wenige Tests abgeschlossen, die Ergebnisse sind ungenau.");
                const filledValues = values.map((value) =>
                  value === null || value === undefined ? 0.5 : value
                );
                setQueryVector(filledValues);
              } else {
                setError("Beginne die Tests um deine persönlichen Ergbnisse zu erhalten.");
              }
            } else {
              setError(null);
              setQueryVector(values);
            }
          } else {
            setQueryVector(null);
            setError("Error.");
            setShowDefaultCourses(true); // Add this line
          }
        } else {
          setQueryVector(null);
          setError("Nicht eingeloggt.");
          setShowDefaultCourses(true); // Add this line
        }
      });
    };

    fetchQueryVector();
  }, []);

  // New useEffect to call queryPinecone when queryVector is set
  useEffect(() => {
    if (queryVector !== null && !queryPineconeCalled) {
      queryPinecone();
      setQueryPineconeCalled(true); // Ensure it's called only once
    }
  }, [queryVector]);

  const searchByCourseID = async () => {
    if (!selectedCourse || !selectedCourse.value) {
      // No need to set an error here anymore
      return;
    }
    setShowDefaultCourses(false);

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}/getByID`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: selectedCourse.value.trim(),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data) {
          setResult([data]);
        } else {
          setError("Keine Daten für den ausgewählten Kurs gefunden.");
        }
      } else {
        const errorText = await response.text();
        console.error("Error:", response.statusText, errorText);
        setError(`Fehler: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Netzwerkfehler oder Server ist nicht erreichbar.");
    } finally {
      setLoading(false);
    }
  };

  const queryPinecone = async () => {
    if (!queryVector || queryVector.includes(null)) {
      setShowDefaultCourses(true);
      return;
    }

    setLoading(true);
    setError(null);
    setShowDefaultCourses(false); // Hide default courses when queryPinecone is called

    let filter = {};

    if (selectedKategorien.length > 0) {
      filter.Kategorie = { $in: selectedKategorien };
    }

    if (selectedTyp.length > 0) {
      filter.Type = { $in: selectedTyp };
    }

    if (selectedSchwierigkeit.length > 0) {
      selectedSchwierigkeit.forEach((item) => {
        if (item === "Schwierig") {
          filter.Schwierigkeit = "Hoch";
        } else if (item === "Schwierig ausschließen") {
          filter.Schwierigkeit = { $ne: "Hoch" };
        }
      });
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}/queryPinecone`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            queryVector,
            filter,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setResult(data);
      } else {
        const errorText = await response.text();
        console.error("Error:", response.statusText, errorText);
        setError(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Network error or server is down.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div
        className="flex flex-col items-center justify-center min-h-screen bg-gray-50"
        style={{
          position: "relative",
          minHeight: "100vh",
          backgroundImage: `
            linear-gradient(to bottom, rgba(255, 255, 255, 0) 200px, rgba(255, 255, 255, 0.5) 250px, rgba(255, 255, 255, 0.7) 300px, rgba(249 250 251 / var(--tw-bg-opacity)) 350px),
            radial-gradient(circle, rgba(23, 37, 84, 0.5) 1px, transparent 1px)
          `,
          backgroundSize: "100% 100%, 25px 25px",
          backgroundRepeat: "no-repeat, repeat",
        }}
      >
        {/* Search Section */}
        <div className="w-full max-w-5xl px-4">
          <div className="text-center py-8">
            <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900 mb-2">
              Entdecke Deine Studiengänge
            </h1>
            <p className="text-md md:text-lg text-gray-600">
              Finde die besten Studiengänge, die zu deiner Persönlichkeit
              passen.
            </p>
          </div>

          <div className="flex flex-row justify-center items-center  mb-6">
            <Suche
              courseOptions={courseOptions}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              searchByCourseID={searchByCourseID}
              queryPinecone={queryPinecone} // Pass queryPinecone as a prop
              loading={loading}
            />
            <IconButton
              onClick={toggleFilter}
              size="large"
              style={{ color: "#181826" }} // Setze die Primärfarbe
              className="ml-2"
            >
              {showFilter ? (
                <FilterAltOffRoundedIcon fontSize="large" /> // Größeres Icon
              ) : (
                <FilterAltRoundedIcon fontSize="large" /> // Größeres Icon
              )}
            </IconButton>
          </div>
          {showFilter && (
            <FilterComponent
              selectedKategorien={selectedKategorien}
              setSelectedKategorien={setSelectedKategorien}
              selectedTyp={selectedTyp}
              setSelectedTyp={setSelectedTyp}
              selectedSchwierigkeit={selectedSchwierigkeit}
              setSelectedSchwierigkeit={setSelectedSchwierigkeit}
            />
          )}
          {/* Error Message */}
          {error && (
            <Alert severity="warning" className="mb-6">
              {error}
            </Alert>
          )}
          {/* Ladeanimation unter der Suchleiste anzeigen */}
          {loading && <SkeletonLoader />}
          {/* Default Courses */}
          {showDefaultCourses && <DefaultCourses />}

          {showDefaultCourses && (
            <AutoPopup
              title="Willkommen in unserem Finder! 👋"
              content="Deinen Score kannst du sehen, nachdem du alle Tests abgeschlossen hast."
              confirmText="Später"
              navigateText="Los geht's"
            />
          )}

          {/* Search Results */}
          {result && (
            <div className="w-full max-w-6xl">
              <SearchCourses result={result} queryVector={queryVector} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
